<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="#1976d2" size="128" is-full-screen />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Other Website Requests
                  <v-spacer></v-spacer>
                  <v-flex pl-0 pl-xs-0 pl-sm-0 pl-md-2 pl-lg-2 pl-xl-2 pt-4>
                    <v-select v-model="status" :items="statusItems" item-text="name" item-value="value"
                      item-color="#8d8d8d" color="#8d8d8d" outlined dense label="Status" clearable>
                    </v-select>
                  </v-flex>
                  <v-flex pl-0 pl-xs-0 pl-sm-0 pl-md-2 pl-lg-2 pl-xl-2 pt-4>
                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                      offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="from" label="From" outlined dense v-bind="attrs" v-on="on"
                          clearable></v-text-field>
                      </template>
                      <v-date-picker v-model="from" no-title @input="menu1 = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex pl-0 pl-xs-0 pl-sm-0 pl-md-2 pl-lg-2 pl-xl-2 pt-4>
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                      offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="to" label="To" outlined dense v-bind="attrs" v-on="on"
                          clearable></v-text-field>
                      </template>
                      <v-date-picker v-model="to" no-title @input="menu2 = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-card-title>
                <v-layout wrap v-if="list.length > 0">
                  <v-flex xs12>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Link</th>
                            <th>WhatsApp Number</th>
                            <th>Occasion</th>
                            <th>Occasion Date</th>
                            <th class="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in list" :key="i">
                            <td>
                              <span>{{ item.createdAt.slice(0, 10) }}</span>
                            </td>
                            <td>
                              <span v-if="item.user">{{ item.user.firstname }}
                                {{ item.user.lastname }}</span>
                                <span v-else>Guest</span>
                            </td>
                            <td>
                              <a target="_blank" :href="item.websitelink">
                                {{ item.websitelink }}</a>
                            </td>
                            <td>{{ item.phone }}</td>
                            <td>
                              <span>{{ item.occassion }}</span>
                            </td>
                            <td>
                              <span v-if="item.occassionDate">{{ item.occassionDate.slice(0, 10) }}</span>
                            </td>
                            <td>
                              <v-layout wrap justify-center v-if="item.status == 'Pending'">
                                <v-flex xs12 align-self-center lg9 pt-6>
                                  <!-- :disabled="item.verificationStatus == 'Verified'" -->
                                  <v-select v-model="item.status" :items="verifyStatus" item-text="name"
                                    item-value="value" item-color="#8d8d8d" color="#8d8d8d" outlined dense label="Status"
                                    @change="updateStatus(item._id)">
                                  </v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-center v-else>
                                <v-flex xs12 align-self-center lg9 pt-6>
                                  <span>Responded</span>
                                </v-flex>
                              </v-layout>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>

                <v-layout v-else pt-15 justify-center fill-height wrap>
                  <v-flex text-center xs12 lg12>
                    <span style="font-size: 22px; letter-spacing: 1px">
                      No Data Found...
                    </span>
                  </v-flex>
                </v-layout>
                <div class="pt-2" v-if="pages > 1">
                  <v-pagination :length="pages" :total-visible="7" v-model="currentPage" color="#FF0000"></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      showsnackbar: false,
      stylImage: null,
      msg: null,
      dialog: false,
      name: "",
      order: null,
      appLoading: false,
      editedIndex: -1,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 12,
      dialogDelete: false,
      changeStatusDialog: false,
      search: "",
      itemname: "",
      imgId: "",
      addcat: false,
      menu1: false,
      menu2: false,
      date: new Date(),
      from: null,
      to: null,
      status: "",
      statusItems: [
        { name: "Not Responded", value: "Pending" },
        { name: "Responded", value: "Responded" },
      ],
      verifyStatus: [
        { name: "Not Responded", value: "Pending" },
        { name: "Responded", value: "Responded" },
      ],
      headers: [
        { text: "Date", value: "date" },
        { text: "User", value: "user" },
        { text: "Link", value: "link" },
        { text: "WhatsApp Number", value: "phone" },
        { text: "Occasion", value: "occassion" },
        { text: "Occasion Date", value: "occassionDate" },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      list: [],
      data: [],
    };
  },
  watch: {
    from() {
      this.getData();
    },
    to() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
    status() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/otherwebsite/product/request",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          fromDate: this.from,
          toDate: this.to,
          status: this.status,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    updateStatus(id) {
      this.updateDialog = false;
      axios({
        url: "/update/otherwebsite/product/request/status",
        method: "POST",
        data: { id: id, status: "Responded" },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.msg = "Status updated successfully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
  